<template>
  <div class="map" v-if="msgData">
    <div class="map-title" v-if="msgData.content.searchableContent">{{msgData.content.searchableContent}}</div>
    <div class="local" v-if="msgData.content.content.sbt">{{msgData.content.content.sbt}}</div>
    <LoadImage class="img" :src="getMapImg()"></LoadImage>
  </div>
</template>

<script>
import LoadImage from '@/components/LoadImage'
export default {
  components: {
    LoadImage
  },
  data() {
    return {
      msgData: null
    }
  },
  props: {
    info: {
      type: Object,
      default: () => null
    }
  },
  created() {
    if(this.info) {
      this.msgData = Object.assign({}, this.info)
      try {
        this.msgData.content.content = JSON.parse(this.msgData.content.content)
      } catch (error) {}
    }
  },
  methods: {
    getMapImg() {
      const lat = this.info.messageContent.lat
      const lng = this.info.messageContent.long
      const url = `https://restapi.amap.com/v3/staticmap?location=${lng},${lat}&zoom=15&size=480*300&markers=mid,0xffa500,:${lng},${lat}&key=2ee9b66df0a1253feb08569c0afc941f`
      return url
    },
  }
}
</script>

<style lang="scss" scoped>
.map {
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
  width: 500px;
  .map-title {
    padding: 0 12px;
    font-size: 16px;
    font-weight: bold;
    color: #333;
    margin: 12px 0;
  }
  .local {
    padding: 0 12px;
    font-size: 14px;
    color: #50545F;
    margin: 12px 0;
  }
  .img {
    width: 500px;
    height: 150px;
  }
}
</style>